import { RouletteBanner } from 'components/pages/Checkout/Paymment/RouletteBanner';

import {
  ActionTypes,
  IFreightProps,
  IPaymentOptionsProps,
  ISendPurchaseOrder,
  IPurchaseOrderProps,
  IPurchaseOrderDataProps,
  ICartRecoveryProps,
  IUpdateCartData,
  IProductCartItem,
  IRouletteProps,
  IRouletteSpinProps,
} from './types';

export function updateQuantityOfProductInCart({
  sku,
  quantity,
  showAfterRequest,
  GA,
}: IAddProductToCart) {
  return {
    type: ActionTypes.updateQuantityOfProductInCart,
    payload: {
      sku,
      quantity,
      showAfterRequest,
      GA,
    },
  };
}

export interface IAddProductToCart {
  sku: string;
  quantity: number;
  showAfterRequest: boolean;
  GA?: object;
  componenteVitrineId?: number;
}

export function addProductToCart({
  sku,
  quantity,
  showAfterRequest,
  GA,
  componenteVitrineId,
}: IAddProductToCart) {
  return {
    type: ActionTypes.addProductToCart,
    payload: {
      sku,
      quantity,
      showAfterRequest,
      GA,
      componenteVitrineId,
    },
  };
}

interface ILoadCartData {
  showMiniCart?: boolean;
}

export function loadCartData({ showMiniCart }: ILoadCartData) {
  return {
    type: ActionTypes.loadCartData,
    payload: {
      showMiniCart,
    },
  };
}

export function closeMiniCart() {
  return {
    type: ActionTypes.closeMiniCart,
    payload: {},
  };
}

export function loadOthersProductsCart() {
  return {
    type: ActionTypes.loadOthersProductsCart,
    payload: {},
  };
}

export function updateOthersProducts({
  othersProducts,
}: Omit<IUpdateCartData, 'cart' | 'showMiniCart'>) {
  return {
    type: ActionTypes.updateOthersProducts,
    payload: {
      othersProducts,
    },
  };
}

export function updateCartData({ cart, showMiniCart }: IUpdateCartData) {
  return {
    type: ActionTypes.updateCartData,
    payload: {
      cart,
      showMiniCart,
    },
  };
}

export function freightCalculation(zipCode: string) {
  return {
    type: ActionTypes.freightCalculation,
    payload: {
      zipCode,
    },
  };
}

export function updateFreight(freightData: IFreightProps) {
  return {
    type: ActionTypes.updateFreigthData,
    payload: {
      freightData,
    },
  };
}

export function addDiscountCoupon(coupon: string) {
  return {
    type: ActionTypes.addDiscountCoupon,
    payload: {
      coupon,
    },
  };
}

export function removeDiscountCoupon() {
  return {
    type: ActionTypes.removeDiscountCoupon,
    payload: {},
  };
}

export function checkoutStart() {
  return {
    type: ActionTypes.checkoutStart,
    payload: {},
  };
}

export function selectFreightOption(
  code: string,
  subtotalComDesconto: number,
  codigoCupom: string,
  itens: IProductCartItem[],
) {
  return {
    type: ActionTypes.selectFreightOption,
    payload: {
      code,
      subtotalComDesconto,
      codigoCupom,
      itens,
    },
  };
}

export function updatePaymentOptions(paymentOptions: IPaymentOptionsProps) {
  return {
    type: ActionTypes.updatePaymentOptions,
    payload: { paymentOptions },
  };
}

export function sendPurchaseOrder(purchaseOrder: ISendPurchaseOrder) {
  return {
    type: ActionTypes.sendPurchaseOrder,
    payload: {
      purchaseOrder,
    },
  };
}

export function selectShippingAddress(addressId: number) {
  return {
    type: ActionTypes.selectShippingAddress,
    payload: {
      addressId,
    },
  };
}

export function updatePurchaseOrder(purchaseOrder: IPurchaseOrderProps) {
  return {
    type: ActionTypes.updatePurchaseOrder,
    payload: {
      purchaseOrder,
    },
  };
}

export function loadPurchaseOrderData(purchaseOrderTransaction: string) {
  return {
    type: ActionTypes.loadPurchaseOrderData,
    payload: {
      purchaseOrderTransaction,
    },
  };
}

export function updatePurchaseOrderData(
  purchaseOrderData: IPurchaseOrderDataProps,
) {
  return {
    type: ActionTypes.updatePurchaseOrderData,
    payload: {
      purchaseOrderData,
    },
  };
}

export function cleanPurchaseOrderData() {
  return {
    type: ActionTypes.cleanPurchaseOrderData,
    payload: {},
  };
}

export function cleanCheckout() {
  return {
    type: ActionTypes.cleanCheckout,
    payload: {},
  };
}

export function cartRecovery({ cartId, clientCode }: ICartRecoveryProps) {
  return {
    type: ActionTypes.cartRecovery,
    payload: {
      cartId,
      clientCode,
    },
  };
}

export function rouletteVerify() {
  return {
    type: ActionTypes.rouletteVerify,
    payload: {},
  };
}

export function updateRoulette(roletaSorte: IRouletteProps) {
  return {
    type: ActionTypes.updateRoulette,
    payload: {
      roletaSorte,
    },
  };
}

export function rouletteSpin({ fromHome }: IRouletteSpinProps) {
  return {
    type: ActionTypes.rouletteSpin,
    payload: {
      fromHome,
    },
  };
}
