import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import { FiX } from 'react-icons/fi';

import { ContainerWrapper, Container, CloseIcon, Content } from './styles';

interface IModalProps {
  onClose(): void;
  withScroll?: boolean;
  canClose?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  onClose,
  withScroll = false,
  canClose = true,
  children,
}) => {
  const [itsClosing, setItsClosing] = useState(false);
  const handleControlPropagation = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    if (canClose) {
      setItsClosing(true);
      setTimeout(() => {
        onClose();
      }, 200);
    }
  }, [onClose]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'initial';
    };
  }, []);
  return (
    <ContainerWrapper onClick={handleCloseModal} role="presentation">
      <Container
        onClick={handleControlPropagation}
        withScroll={withScroll}
        className={itsClosing && 'closing'}
      >
        <CloseIcon className="close-icon" onClick={handleCloseModal}>
          <FiX />
        </CloseIcon>
        <Content withScroll={withScroll}>{children}</Content>
      </Container>
    </ContainerWrapper>
  );
};

export default Modal;
