import styled, { css, keyframes } from 'styled-components';

import { colors } from 'styles';

interface IModalProps {
  withScroll: boolean;
}

const opacityEntryAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const opacityExitAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const entryAnimation = keyframes`
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const exitAnimation = keyframes`
0% {
  transform: translateY(0px);
}
100% {
  transform: translateY(-200px);
}
`;

export const ContainerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.7);
  padding: 0 1.6rem;

  animation: ${opacityEntryAnimation} 0.3s;
`;

export const Container = styled.div<IModalProps>`
  background: ${colors.background_card};
  margin: 2.4rem;
  min-width: 30rem;
  max-width: 90vw;
  max-height: 90vh;
  min-height: 5rem;

  border-radius: 1rem;

  animation: ${entryAnimation} 0.3s, ${opacityEntryAnimation} 0.3s;
  position: relative;

  ${props =>
    props.withScroll &&
    css`
      height: min-content;
    `}

  &.closing {
    animation: ${exitAnimation} 0.3s, ${opacityExitAnimation} 0.3s;
  }
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: -2.4rem;
  right: -2.4rem;
  z-index: 9;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2.4rem;
  padding: 0.2rem;
  border-radius: 50%;
  border: 0.4rem solid ${colors.white};

  background: ${colors.green_primary};

  color: ${colors.white};

  box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.3);

  cursor: pointer;
`;

export const Content = styled.div<IModalProps>`
  width: 100%;
  height: 100%;
  max-height: 80vh;
  padding: 2.4rem;

  ${props =>
    props.withScroll &&
    css`
      height: min-content;

      overflow-y: scroll;
      overflow-x: hidden;

      ::-webkit-scrollbar-track {
        background-color: #f4f4f4;
      }

      scrollbar-track-color: #f4f4f4;
      ::-webkit-scrollbar {
        width: 0.6rem;
        background: #f4f4f4;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.green_primary};
        border-radius: 1rem;
      }

      border-radius: 1rem;
      scrollbar-base-color: ${colors.green_primary};
    `}
`;
