/* eslint-disable import/no-unresolved */
import React, { FC } from 'react';

// import App, { AppInitialProps, AppContext } from 'next/app';
import { AppProps } from 'next/dist/shared/lib/router/router';
import Head from 'next/head';
// eslint-disable-next-line import/order
import Script from 'next/script';
import { QueryClientProvider, QueryClient } from 'react-query';

// import { AppProps } from 'next/dist/next-server/lib/router/router';
// import { END } from 'redux-saga';

import { InfoFooter } from 'components/common/InfoFooter';
import { Scripts } from 'components/common/Scripts';
import AppProvider from 'hooks';
import { LoaderOnRouteChange } from 'hooks/route';
import Interceptor from 'services/Interceptor';
// eslint-disable-next-line import/order
import { SagaStore, storeWrapper } from 'store';
import 'styles/reset.css';

import GlobalStyle from 'styles/GlobalStyle';

const queryCliente = new QueryClient();

const WrappedApp: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <QueryClientProvider client={queryCliente}>
      <AppProvider>
        <Head>
          {process.env.NEXT_PUBLIC_SITE_BLINDADO && (
            <meta
              name="sb.validation_hash"
              content={process.env.NEXT_PUBLIC_SITE_BLINDADO}
            />
          )}
        </Head>

        <LoaderOnRouteChange />

        <Interceptor />
        <Scripts />

        <GlobalStyle />

        <Component {...pageProps} />

        <InfoFooter />
      </AppProvider>
    </QueryClientProvider>
  );
};

export default storeWrapper.withRedux(WrappedApp);
