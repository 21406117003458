// eslint-disable-next-line no-shadow
export enum ActionTypes {
  loadConfigApplication = 'LOAD_CONFIG_APPLICATION',
  updateConfigApplication = 'UPDATE_CONFIG_APPLICATION',

  setRedirect = 'SET_REDIRECT',
  clearRedirect = 'CLEAR_REDIRECT',

  setUF = 'SET_UF',

  setPorcentagem = 'SET_PORCENTAGEM',

  setBackgroundColorHeader = 'SET_BACKGROUND_HEADER_COLOR',
}

export interface IConfigProps {
  config: IConfigApplicationProps;
  categories: Array<INavigationCategoryProps>;
  redirectData: IRedirectProps;
  uf: IUFProps;
  backgroundColorHeader?: string;
  porcentagem?: string;
}

export interface IConfigApplicationProps {
  aplicacao: {
    lojaUrl: string;
    categoriaPai: string;
  };
  aparencia: {
    logo: string;
    openGraph: string;
    icone: string;
  };
  loja: {
    nome: string;
    blogUrl: string;
    email: string;
    telefone: string;
    televendas: string;
    whatsapp: string;
    horarioAtendimento: string;
    documento: string;
    nomeFantasia: string;
    razaoSocial: string;
    inscricaoEstadual: string;
    codigoPostal: string;
    logradouro: string;
    numero: string;
    bairro: string;
    cidade: string;
    uf: string;
    complemento: string;
    seo_Titulo: string;
    seo_Descricao: string;
    seo_PalavrasChaves: string;
  };
  geral: {
    habilitarSeloDescontoVitrine: boolean;
    exibirDescontoSeloSuperior: number;
  };
  checkout: {
    valorMinimoCompra: number;
  };
  clearSale: {
    ativo: boolean;
    codigo: string;
  };
  chat: {
    ativo: boolean;
    url: string;
  };
  googleTagManager: {
    ativo: boolean;
    idContainer: string;
    idAcompanhamento: string;
  };
  linxImpulse: {
    ativo: boolean;
    apiKey: string;
    secretKey: string;
    vitrine: boolean;
    navigates: boolean;
  };
  ultraDescontao: {
    vigencia: {
      inicio: Date;
      fim: Date;
    };
    esquentaVigencia: {
      inicio: Date;
      fim: Date;
    };
    estaVigente?: boolean;
    esquentaEstaVigente?: boolean;
    seloVigentePdpUrl: string;
    seloVigenteVitrineUrl: string;
    cronometroAtivo: boolean;
    cronometroInicio: Date;
    cronometroFim: Date;
    cronometroImagemUrl: string;
    cronometroImagemMobileUrl: string;
    cronometroLinkUrl: string;
    cronometroUrl: string;
    cronometroDeepLink: string;
    cronometroCallToAction: string;
    corTexto: string;
    corTextoBarato: string;
    corCronometro: string;
  };
  salesForce: {
    faleConoscoAtivo: boolean;
    faleComSidneyAtivo: boolean;
  };
  siteBlindado: {
    ativo: boolean;
  };
  seloEbit: {
    codigo: string;
    sellerName: string;
  };
  avisoTopo: {
    ativo: boolean;
    mensagem: string;
  };
  roletaSorte: {
    ativo: boolean;
    exibirHome: boolean;
  };
  ultraPremio: {
    ativo: boolean;
    imagemUrl: string;
    imagemMobileUrl: string;
    urlRegulamento: string;
  };
}

export interface INavigationCategoryProps {
  categoriaPaiId: string;
  contador: number;
  deepLink: string;
  descricao: string;
  exibirMenu: boolean;
  filhos: Array<INavigationCategoryProps>;
  id: number;
  imagemThumbUrl: string;
  imagemUrl: string;
  nome: string;
  permalink: string;
  tipoDeepLink: string;
  valorDeepLink: string;
}

export interface IRedirectProps {
  redirect: boolean;
  path: string;
}

export interface IUFProps {
  value: string;
  label: string;
}
