import { useCallback, useEffect, useState } from 'react';

import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { useLoading, useNotices } from 'hooks';
import { IResponse } from 'store/modules/common-types';
import { setPorcentagem } from 'store/modules/config';
import { IUserProps, signOut } from 'store/modules/profile';
import { IUFProps } from 'utils/getUF';

import api, { apiV2 } from './api';

export default function Interceptor() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [redirectAfterLogin, setRedirectAfterLogin] = useState(false);
  const { setLoadingOn, setLoadingOff } = useLoading();

  const { addNotices } = useNotices();

  const handleSetNotices = useCallback(
    (data: IResponse) => {
      if (data.contemErros) {
        addNotices({ messages: data.erros, type: 'errors' });
      }
      if (data.contemAvisos) {
        addNotices({ messages: data.avisos, type: 'warn' });
      }

      if (!!data.todasMensagens && !!data.todasMensagens.length) {
        addNotices({ messages: data.todasMensagens, type: 'allMessages' });
      }
    },
    [addNotices],
  );

  const handleRequestSuccess = async (request: AxiosRequestConfig) => {
    const userStorage = Cookies.get('@Pet:user');
    const ufStorage = Cookies.get('@Pet:cookies:uf');
    const idSession = Cookies.get('@Pet:idSession');

    if (userStorage) {
      const userInformation: IUserProps = JSON.parse(userStorage);
      const uf: IUFProps = JSON.parse(ufStorage);
      request.headers.Authorization = `Bearer ${userInformation.token}`;
      request.headers['x-uf'] = uf.value || userInformation.uf;
    } else {
      request.headers.Authorization = '';
    }

    if (!idSession) {
      const newId = uuidv4();
      Cookies.set('@Pet:idSession', newId);
      request.headers['x-cliente-token'] = newId;
    } else {
      request.headers['x-cliente-token'] = idSession;
    }

    if (request.url === 'checkout/pedido') {
      setLoadingOn('Processando pedido...');
    } else if (request.url !== 'configuracoes') {
      setLoadingOn();
    }

    return request;
  };

  const handleRequestError = (err: AxiosError<IResponse>) => {
    setLoadingOff();
    if (err.response) {
      handleSetNotices(err.response?.data);
    }
  };

  const handleResponseSuccess = async (response: AxiosResponse<IResponse>) => {
    setLoadingOff();

    const percent = response.headers['x-porcentagem'];

    dispatch(setPorcentagem(percent));

    if (response.data && response.data.id) {
      handleSetNotices(response.data);
    }

    if (
      response.config.url === 'cliente/alterar-senha/' &&
      response.status === 200
    ) {
      addNotices({
        messages: ['Sua senha foi alterada com sucesso'],
        type: 'allMessages',
      });
    }
    if (
      response.config.url === '/cliente-pf/atualizar' &&
      response.status === 200
    ) {
      addNotices({
        messages: ['Seus dados foram salvos com sucesso'],
        type: 'allMessages',
      });
    }

    return response;
  };

  const handleResponseError = (err: AxiosError<IResponse> & AxiosResponse) => {
    setLoadingOff();

    if (err?.response) {
      if (err?.response.status === 400) {
        handleSetNotices(err?.response?.data);
      } else if (err?.response.status === 401) {
        setRedirectAfterLogin(true);
      }
    }

    if (err.isAxiosError) {
      return Promise.reject(err.response);
    }

    return Promise.reject(err);
  };

  useEffect(() => {
    if (redirectAfterLogin) {
      router.push(`/identificacao?ReturnUrl=${router.asPath}`);

      dispatch(signOut({}));

      setRedirectAfterLogin(false);
    }
  }, [router, redirectAfterLogin, dispatch]);

  useEffect(() => {
    api.interceptors.request.use(
      async (request: AxiosRequestConfig) => handleRequestSuccess(request),
      (err: AxiosError<IResponse>) => handleRequestError(err),
    );

    api.interceptors.response.use(
      (response: AxiosResponse<IResponse>) => handleResponseSuccess(response),
      async (err: AxiosError<IResponse> & AxiosResponse) =>
        handleResponseError(err),
    );

    apiV2.interceptors.request.use(
      async (request: AxiosRequestConfig) => handleRequestSuccess(request),
      (err: AxiosError<IResponse>) => handleRequestError(err),
    );

    apiV2.interceptors.response.use(
      (response: AxiosResponse<IResponse>) => handleResponseSuccess(response),
      async (err: AxiosError<IResponse> & AxiosResponse) =>
        handleResponseError(err),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoadingOn, setLoadingOff, handleSetNotices, addNotices, dispatch]);

  return null;
}
