import { colors } from 'styles';

import { Container, Activity } from './styles';

const ActivityIndicator = () => {
  return (
    <>
      <Container>
        <span>
          <Activity>
            <circle
              cx="60"
              cy="60"
              r="12"
              stroke={colors.green_primary}
              strokeWidth="4"
              fill="none"
            />
          </Activity>
        </span>
      </Container>
    </>
  );
};

export { ActivityIndicator };
