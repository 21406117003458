import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.button`
  height: max-content;
  min-height: 4.2rem;
  width: 100%;
  padding: 0.8rem 1.2rem;
  font-size: 1.6rem;

  border: 0;
  background: var(--primary-color);
  font-weight: 700;
  border-radius: 0.5rem;
  color: #fff;
  box-shadow: 2px 4px 5px #bec9c5;

  transition: 0.3s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.6;
  }
`;
