import AgentKeepAlive from 'agentkeepalive';
import axios from 'axios';

const agentKeepAliveCfg = {
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60 * 1000, // 1 minute
  freeSocketTimeout: 30 * 1000, // 30 seconds
  keepAlive: true
};

const defaultAxiosConfig = {
  maxRedirects: 10,
  timeout: 60 * 1000, // 1 minute
  maxContentLength: 50 * 1000 * 1000, // 50MB
  httpAgent: new AgentKeepAlive(agentKeepAliveCfg),
  httpsAgent: new AgentKeepAlive.HttpsAgent(agentKeepAliveCfg),
  headers: {
    'x-api-authorization': `Basic ${process.env.NEXT_PUBLIC_API_AUTHORIZATION}`,
    'Content-Type': 'application/json',
    'x-cv': 1,
    'x-loja-id': 3,
  },
};

const api = axios.create({
  ...defaultAxiosConfig,
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/${process.env.NEXT_PUBLIC_API_VERSION}`,
});

const apiV2 = axios.create({
  ...defaultAxiosConfig,
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/v2`,
});

export default api;

export { apiV2 };
