import styled, { keyframes } from 'styled-components';

import { colors } from 'styles';

const opacityKeyFrames = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 999;

  background: rgba(255, 255, 255, 0.7);

  animation: ${opacityKeyFrames} 0.3s;
`;

export const Content = styled.h2`
  animation: ${opacityKeyFrames} 2s infinite;
  color: ${colors.green_primary};
`;
