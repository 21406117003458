import React, { memo, useEffect } from 'react';

import { Container, Content } from './styles';

interface ILoadingProps {
  loading: boolean;
  message: string;
}

const Loading: React.FC<ILoadingProps> = ({ loading, message }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'initial';
    };
  }, []);

  if (loading) {
    return (
      <Container>
        <Content>{message}</Content>
      </Container>
    );
  }
  return null;
};

export default memo(Loading);
