import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 50vw;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background-color: rgba(250, 250, 250, 0.4);
    border-radius: 50%;
  }
`;

export const Activity = styled.svg`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  stroke-dasharray: 10;
  animation: dash 2s infinite;

  width: 12rem;
  height: 12rem;

  @keyframes dash {
    to {
      stroke-dashoffset: 100;
    }
  }
`;
