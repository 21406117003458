import { AxiosResponse } from 'axios';
import { all, takeLatest, call, put } from 'redux-saga/effects';

import api from 'services/api';

import { loadHomeData, updateHomeData } from './actions';
import { ActionTypes, IHomeDataResponse } from './types';

type ILoadHomeDataProps = ReturnType<typeof loadHomeData>;

function* loadHomeDataRequest({ payload }: ILoadHomeDataProps) {
  try {
    const { id } = payload;
    const response: AxiosResponse<IHomeDataResponse> = yield call(
      api.get,
      `vitrine/home`,
      {
        params: {
          vitrineId: id,
        },
      },
    );

    yield put(updateHomeData(response.data));
  } catch (err) {}
}

export default all([takeLatest(ActionTypes.loadHomeData, loadHomeDataRequest)]);
