import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${colors.background_card};
  max-width: 60vw;
  margin-right: 2.4rem;
  padding: 1.2rem;
  box-shadow: 2px 2px 10px black;
  color: ${colors.text_gray};
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;

  font-size: 1.2rem;
  line-height: 1.6rem;
  text-align: justify;

  z-index: 100;

  a {
    text-decoration: underline;
  }

  button:last-child {
    max-width: max-content;
    margin-top: 1.2rem;
    font-size: 1.2rem;
    align-self: flex-end;
  }

  @media (max-width: 425px) {
    max-width: 90vw;
  }
`;
