// eslint-disable-next-line no-shadow
export enum ActionTypes {
  loadProductData = 'LOAD_PRODUCT_DATA',
  updateProductData = 'UPDATE_PRODUCT_DATA',

  loadProductReviews = 'LOAD_PRODUCT_REVIEWS',
  addProductReview = 'ADD_PRODUCT_REVIEW',
  updateProductReviews = 'UPDATE_PRODUCT_REVIEWS',

  productFreightCalculation = 'PRODUCT_FREIGHT_CALCULATION',
  updateProductFreightCalculation = 'UPDATE_PRODUCT_FREIGHT_CALCULATION',
}

export interface ILoadReviewsRequest {
  productId: number;
  page?: number;
  quantity?: number;
}

export interface ILoadReviewResponse {
  reviews: Array<IReviewProps>;
}

export interface IAddProductReviewRequest {
  productId: number;
  title: string;
  comment: string;
  recommends: boolean;
  evaluation: number;
}

export interface IProductFreightCalculationRequest {
  sku: string;
  zipCode: string;
  quantity?: number;
}

export interface IProductState {
  product: IProductProps;
  reviews: Array<IReviewProps>;
  shipping_options: Array<IShippingProps>;
}

export interface IReviewProps {
  nome: string;
  cidade: string;
  uf: string;
  titulo: string;
  comentario: string;
  nota: number;
  recomenda: boolean;
  criadoEm: Date;
}

export interface IShippingProps {
  codigo: string;
  nome: string;
  valor: string;
  valorCobrado: number;
  prazoEntrega: string;
  dataAtual: Date;
  prazoEntregaDeEmDias: number;
  prazoEntregaAteEmDias: number;
  mensagem: string;
}

export interface ILoadProductDataRequest {
  sku: string | Array<string>;
}

export interface IProductProps {
  id: number;
  nome: string;
  imagemPrincipal: IImageProps;
  imagens: Array<IImageProps>;
  precoUnitarioItemKitTexto: string;
  comissao: number;
  comissaoTexto: string;
  precoDeTexto: string;
  precoPorTexto: string;
  precoPor: string;
  selos: boolean;
  preco: {
    porcentagemDesconto: number;
    precoDe: number;
    precoPor: number;
    precoPorBoletoBancario: number;
    precoUnitarioItemKit: number;
  };
  navegacao: Array<INavigationProps>;
  codigo: string;
  marca: {
    id: number;
    nome: string;
    permalink: string;
  };
  correlatados: Array<IProductPropsPDP>;
  descricaoGeral: string;
  sku: string;
  quantidadeMinimaVenda: number;
  quantidadeMaximaVenda: number;
  descricaoTecnica: string;
  descricaoAplicacao: string;
  descricaoAdicional: string;
  resumoAvaliacao: IEvaluationSummaryProps;
  farmacia: {
    advertenciaAnvisa: string;
    advertenciaMS: string;
    classeTerapeutica: Array<string>;
    contraIndicacoes: string;
    generico: boolean;
    indicacoes: string;
    leite: boolean;
    medicamento: boolean;
    principioAtivo: Array<{
      nome: string;
    }>;
    registroMS: string;
    retencaoReceita: boolean;
    sngpc: boolean;
    tarja: string;
    vendaControlada: boolean;
  };
  ehFavorito: boolean;
  boletoBancarioAtivo: boolean;
  descontoBoleto: number;
  habilitaAvaliacao: boolean;
  lojasDisponiveis: Array<{
    codigo: string;
    quantidade: number;
    cd: number;
    dadosCd: string;
  }>;
  tipo: string;
  permalink: string;
  complemento: string;
  notaMedia: number;
  quantidadeMultiplaVenda: number;
  videoUrl: string;
  aplicarPromocao: boolean;
  freteGratis: boolean;
  ultraDesconto: boolean;
  bonificacaoClube: number;
  possuiGenerico: boolean;
  bloqueadoVendaSite: boolean;
  parcelamentoMaximo: number;
  valorMinimoParcela: number;
  estoqueLoja: number;
  pontosLivelo: number;
  medidas: {
    peso: number;
    altura: number;
    largura: number;
    profundidade: number;
  };
  seo: {
    seo_Titulo: string;
    seo_Descricao: string;
    seo_PalavrasChaves: string;
  };
  categoria: {
    id: number;
    nome: string;
    nomeHierarquia: string;
    categoriaPaiId: number;
  };
  parcelamento: {
    parcelas: Array<{
      numero: number;
      valor: number;
      desconto: number;
      taxaJuros: number;
      valorJuros: number;
      valorTexto: string;
      descricao: string;
    }>;
    totalParcelas: number;
    ultimaParcela: {
      numero: number;
      valor: number;
      desconto: number;
      taxaJuros: number;
      valorJuros: number;
      valorTexto: string;
      descricao: string;
    };
    ultimaParcelaSemJuros: {
      numero: number;
      valor: number;
      desconto: number;
      taxaJuros: number;
      valorJuros: number;
      valorTexto: string;
      descricao: string;
    };
  };
  promocaoProgressiva: {
    id: number;
    codigo: string;
    nome: string;
    imagemUrl: string;
    fatorKit: number;
    ativo: boolean;
    criadoEm: Date;
  };
  promocoesProgressivasRegras: Array<{
    quantidade: number;
    precoUnitario: number;
    precoUnitarioTexto: string;
  }>;
  brinde: {
    produtoId: number;
    skuOrigem: string;
    produtoBrindeId: number;
    quantidade: number;
    vigencia_Inicio: Date;
    vigencia_Fim: Date;
    descricao: string;
    valorLiquidoVenda: number;
  };
  produtoBrinde: {
    codigo: string;
    sku: string;
    nome: string;
    imagemUrlMini: string;
    imagemUrlMedia: string;
    imagemUrlGrande: string;
    peso: number;
    altura: number;
    largura: number;
    profundidade: number;
    preco: number;
  };
  variacoes: Array<{
    sku: string;
    estoque: number;
    preco: {
      precoDe: number;
      precoPor: number;
      precoPorBoletoBancario: number;
      porcentagemDesconto: number;
    };
    parcelamento: {
      parcelas: Array<{
        numero: number;
        valor: number;
        desconto: number;
        taxaJuros: number;
        valorJuros: number;
        valorTexto: string;
        descricao: string;
      }>;
      totalParcelas: number;
      ultimaParcela: {
        numero: number;
        valor: number;
        desconto: number;
        taxaJuros: number;
        valorJuros: number;
        valorTexto: string;
        descricao: string;
      };
      ultimaParcelaSemJuros: {
        numero: number;
        valor: number;
        desconto: number;
        taxaJuros: number;
        valorJuros: number;
        valorTexto: string;
        descricao: string;
      };
    };
    ultimaParcela: {
      numero: number;
      valor: number;
      desconto: number;
      taxaJuros: number;
      valorJuros: number;
      valorTexto: string;
      descricao: string;
    };
    disponivel: boolean;
  }>;
  ultimaParcela: {
    numero: number;
    valor: number;
    desconto: number;
    taxaJuros: number;
    valorJuros: number;
    valorTexto: string;
    descricao: string;
  };
  deepLink: string;
  disponivel: boolean;
  indisponivel: boolean;
  todasCategoriasId: Array<number>;
  variacaoPadrao: {
    sku: string;
    estoque: number;
    preco: {
      precoDe: number;
      precoPor: number;
      precoPorBoletoBancario: number;
      porcentagemDesconto: number;
    };
    parcelamento: {
      parcelas: Array<{
        numero: number;
        valor: number;
        desconto: number;
        taxaJuros: number;
        valorJuros: number;
        valorTexto: string;
        descricao: string;
      }>;
      totalParcelas: number;
      ultimaParcela: {
        numero: number;
        valor: number;
        desconto: number;
        taxaJuros: number;
        valorJuros: number;
        valorTexto: string;
        descricao: string;
      };
      ultimaParcelaSemJuros: {
        numero: number;
        valor: number;
        desconto: number;
        taxaJuros: number;
        valorJuros: number;
        valorTexto: string;
        descricao: string;
      };
    };
    ultimaParcela: {
      numero: number;
      valor: number;
      desconto: number;
      taxaJuros: number;
      valorJuros: number;
      valorTexto: string;
      descricao: string;
    };
    disponivel: boolean;
  };
  creditoBonificacaoClube: number;
  enviarReceita: boolean;
  ultraPremio: boolean;
}

export interface IProductCarouselProps {
  aplicarPromocao: boolean;
  selos: boolean;
  bloqueadoVendaSite: boolean;
  precoUnitarioItemKitTexto: string;
  categoria: string;
  categoriaId: number;
  codigo: string;
  complemento: string;
  controleEstoque: number;
  desconto: number;
  disponivel: boolean;
  estoque: number;
  estoqueLojas: string;
  fatorKit: number;
  freteGratis: boolean;
  id: number;
  imagemUrl: string;
  imagens: Array<string>;
  livelo: boolean;
  marca: string;
  marcaId: number;
  naoPossuiRestricaoVenda: boolean;
  nome: string;
  notaMedia: number;
  numeroParcela: number;
  permalink: string;
  possuiAlgumaRestricaoVenda: boolean;
  possuiBrinde: boolean;
  possuiGenerico: boolean;
  precoDe: number;
  precoDeTexto: string;
  precoPor: number;
  precoPorTexto: string;
  promocaoImagemUrl: string;
  comissao: number;
  comissaoTexto: string;
  removido: boolean;
  retencaoReceita: boolean;
  sku: string;
  ultraDesconto: boolean;
  valorParcela: number;
  valorParcelaTexto: string;
  variacoes: Array<string>;
  vendaControlada: boolean;
  ultraPremio: boolean;
}

export interface IProductEvaluationProps {
  nome: string;
  cidade: string;
  uf: string;
  titulo: string;
  comentario: string;
  nota: number;
  recomenda: boolean;
  criadoEm: Date;
}

export interface IEvaluationSummaryProps {
  media: number;
  notas: Array<{
    nota: number;
    total: number;
    porcentagem: number;
  }>;
  produtoId: number;
  total: number;
}

export interface IImageProps {
  grande: string;
  media: string;
  mini: string;
  pequena: string;
  principal: boolean;
}

export interface IProductPropsPDP {
  codigo: string;
  deepLink: string;
  id: number;
  imagemUrl: string;
  nome: string;
  nomeTruncado: string;
  permalink: string;
  precoDe: number;
  precoDeTexto: string;
  precoPor: number;
  precoPorTexto: string;
  desconto: number;
  comissao: number;
  comissaoTexto: string;
  selos: boolean;
  freteGratis: boolean;
  ultraPremio: boolean;
  ultraDesconto: boolean;
}

export interface INavigationProps {
  id: number;
  nome: string;
  permalink: string;
}
