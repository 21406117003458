import styled from 'styled-components';

import { colors } from 'styles';

export const Container = styled.div`
  max-width: 70rem;
  min-width: 20rem;
  color: ${colors.green_primary};

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  h2 {
    margin-bottom: 3.6rem;
  }

  .warn {
    font-size: 1.8rem;
  }

  p + p {
    margin-top: 1.2rem;
  }

  a {
    color: ${colors.green_primary};
  }
  .section-buttons {
    display: flex;

    align-self: flex-end;

    button {
      background: transparent;
      color: ${colors.green_primary};

      & + button {
        background: ${colors.green_primary};
        color: ${colors.text_white};
        margin-left: 1.2rem;
      }
    }
  }

  button {
    margin-top: 3.6rem;
    align-self: flex-end;
    width: 15rem;
  }

  @media (max-width: 350px) {
    .section-buttons {
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;

        & + button {
          margin-left: 0;
          margin-top: 1.2rem;
        }
      }
    }
  }
`;
