import React from 'react';

import { Container } from './styles';

export function InfoFooter() {
  return (
    <Container>
      <p>
        Ultrafarma Pet LTDA – CNPJ: 48.415.777/0001-43 / São Paulo/SP /IE:
        138.093.708.118 / Horários de Funcionamento site: 24 horas por dia,
        todos os dias da semana. As informações contidas neste site como
        promoções e ofertas não substituem, em hipótese alguma, a medicação
        prescrita pelo profissional da área médica veterinária. Somente o médico
        veterinário está em condições de diagnosticar qualquer problema de saúde
        e prescrever o tratamento adequado. Qualquer dúvida sobre prescrição
        médica e produtos divulgados em nosso site, entre em contato por meio do
        atendimento por e-mail. As fotos contidas em nosso site são meramente
        ilustrativas. Os preços e promoções são válidos apenas para o site
        www.ultrafarmapet.com.br. Preços e disponibilidades sujeitos a
        alterações ao decorrer do dia ** As parcelas mínimas nos cartões que
        aceitam parcelamento são de R$ 20,00.
      </p>
    </Container>
  );
}
