import { useState } from 'react';

import Cookies from 'js-cookie';
import { FiX } from 'react-icons/fi';

import Button from '../../Button';
import { Container } from './styles';

type CookieWarningProps = {
  handleSetOpenWarningCookie: () => void;
};

export function CookiesWarning({
  handleSetOpenWarningCookie,
}: CookieWarningProps) {
  function handleAccept() {
    handleSetOpenWarningCookie();

    Cookies.set('@Pet:cookies', JSON.stringify(false), {
      expires: 1,
    });
  }

  return (
    <Container>
      <p>
        O Ultrafarma Pet está atento às normas de proteção de dados pessoais
        vigentes no Brasil (Lei Geral de Proteção de Dados Pessoais - LGPD), por
        isso estamos atualizando nossos procedimentos e políticas relacionadas
        ao tratamento de dados pessoais dos nossos clientes. Se você deseja
        saber como os seus dados são utilizados e como você pode exercer seus
        direitos enquanto titular, acesse a nossa{' '}
        <a
          href="/politicas-de-privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade.
        </a>
      </p>
      <Button onClick={handleAccept}>
        Aceito e desejo continuar navegando
      </Button>
    </Container>
  );
}
