// import { useCallback, useEffect, useState } from 'react';

import Script from 'next/script';
import { useSelector } from 'react-redux';

import { IState } from 'store';
import { IConfigProps } from 'store/modules/config';
// import { objectIsEmpty } from 'utils/objectEmpty';

export function Scripts() {
  const { config } = useSelector<IState, IConfigProps>(state => state.config);
  // const [csScriptAdded, setCsScriptAdded] = useState(false);
  // const { ativo, codigo } = config.clearSale;

  return (
    <>
      {config.clearSale?.ativo && config.clearSale?.codigo && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function (a, b, c, d, e, f, g) {
                a["CsdmObject"] = e;
                (a[e] =
                  a[e] ||
                  function () {
                    (a[e].q = a[e].q || []).push(arguments);
                  }),
                  (a[e].l = 1 * new Date());
                (f = b.createElement(c)), (g = b.getElementsByTagName(c)[0]);
                f.async = 1;
                f.src = d;
                g.parentNode.insertBefore(f, g);
              })(window, document, "script", "//device.clearsale.com.br/m/cs.js", "csdm");
              csdm("app", "${config.clearSale?.codigo}");
            `,
          }}
          defer
        />
      )}

      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', 'GTM-PN85G9L');
          `,
        }}
      />

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="GTM"
        />
      </noscript>
    </>
  );
}
