import React from 'react';

import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { MiniCartProvider } from './minicart';
import { NoticesProvider } from './notices';

const hooks: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <NoticesProvider>
        <LoadingProvider>
          <MiniCartProvider>{children}</MiniCartProvider>
        </LoadingProvider>
      </NoticesProvider>
    </AuthProvider>
  );
};

export default hooks;

export * from './auth';
export * from './loading';
export * from './minicart';
export * from './notices';
