/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { createWrapper, MakeStore, Context } from 'next-redux-wrapper';
import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware, { Task } from 'redux-saga';

import { ICartState } from './modules/cart/types';
import { IConfigProps } from './modules/config/types';
import { IHomeDataState } from './modules/home/types';
import { IProductState } from './modules/product/types';
import { IProfileState } from './modules/profile/types';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import { IShowCaseState } from './modules/showcase/types';

export interface IState {
  cart: ICartState;
  product: IProductState;
  showcase: IShowCaseState;
  profile: IProfileState;
  config: IConfigProps;
  homeData: IHomeDataState;
}

export interface SagaStore extends Store {
  sagaTask?: Task;
}

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools({ trace: true })(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore: MakeStore<IState> = (context: Context) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const storeWrapper = createWrapper<IState>(makeStore, { debug: false });
