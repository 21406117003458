import { IProductCarouselProps } from '../product/types';
import { IBannerProps } from '../showcase/types';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  loadHomeData = 'LOAD_HOME_DATA',
  updateHomeData = 'UPDATE_HOME_DATA',
}

export interface IHomeDataState {
  banners: Array<IBannerProps>;
  components: Array<IComponentsProps>;
}

export interface IHomeDataResponse {
  banners: Array<IBannerProps>;
  componentes: Array<IComponentsProps>;
}

export interface IComponentsProps {
  exibirTitulo: boolean;
  itens: IItemsProps[];
  ordem: number;
  template: string;
  tipo: string;
  titulo: string;

  bannerImagemUrl: string;
  bannerLinkUrl: string;
  campanha: string;
  componenteVitrineId: number;
}

interface IItemsProps extends IProductCarouselProps {
  imagemUrl: string;
  redirecionarUrl: string;

  campanha: string;
  imagemMobileUrl: string;
  ordem: number;
  titulo: string;
}
