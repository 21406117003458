import styled from 'styled-components';

import { colors, metrics } from 'styles';

export const Container = styled.div`
  background-color: #cecece;

  p {
    width: 100%;
    max-width: ${metrics.container_max_width};
    margin: 0 auto;
    padding: 2.4rem 3.6rem;
    color: ${colors.text_gray};
    font-size: 1.2rem;
  }
`;
