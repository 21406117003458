const colors = {
  // Colors
  // background: "#f4f4f4",
  background: '#ffffff',
  background_card: '#FFFFFF',

  green_primary: '#542288',
  green_dark: '#542288',
  green_light: '#542288',
  green_light2: '#542288',
  blue_light: '#6bc0ea',

  gray: '#d4d4d4',
  gray_light: '#eeeeee',

  white: '#ffffff',
  yellow: '#FFCE00',
  yellow_light: '#fae54c',
  green: '#542288',

  red_error: '#c53030',

  // Text
  text_black: '#353232',
  text_white: '#ffffff',
  text_gray: '#646464',

  // Icon colors
  icon_black: '#353232',
};

export default colors;
