import { IResponse } from '../common-types';
import { IProductCarouselProps } from '../product/types';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  loadCartData = 'LOAD_CART_DATA',
  updateCartData = 'UPDATE_CART_DATA',

  closeMiniCart = 'CLOSE_MINI_CART',

  loadOthersProductsCart = 'LOAD_OTHERS_PRODUCTS_CART',
  updateOthersProducts = 'UPDATE_OTHERS_PRODUCTS',

  addProductToCart = 'ADD_PRODUCT_TO_CART',
  updateQuantityOfProductInCart = 'UPDATE_QUANTITY_OF_PRODUCT_IN_CART',

  freightCalculation = 'FREIGHT_CALCULATION',
  updateFreigthData = 'UPDATE_FREIGHT_DATA',

  addDiscountCoupon = 'ADD_DISCOUNT_COUPON',
  removeDiscountCoupon = 'REMOVE_DISCOUNT_COUPON',

  checkoutStart = 'CHECKOUT_START',
  cleanCheckout = 'CLEAN_CHECKOUT',

  selectFreightOption = 'SELECT_FREIGHT_OPTION',

  updatePaymentOptions = 'UPDATE_PAYMENT_OPTIONS',

  sendPurchaseOrder = 'SEND_PURCHASE_ORDER',
  updatePurchaseOrder = 'UPDATE_PURCHASE_ORDER',
  loadPurchaseOrderData = 'LOAD_PURCHASE_ORDER_DATA',
  updatePurchaseOrderData = 'UPDATE_PURCHASE_ORDER_DATA',
  cleanPurchaseOrderData = 'CLEAN_PURCHASE_ORDER_DATA',

  selectShippingAddress = 'SELECT_SHIPPING_ADDRESS',

  cartRecovery = 'CART_RECOVERY',

  rouletteVerify = 'ROULETTE_VERIFY',
  updateRoulette = 'UPDATE_ROULETTE',
  rouletteSpin = 'ROULETTE_SPIN',
}

export interface ICartState {
  cart: ICartProps;
  showMiniCart: boolean;
  freight: IFreightProps;
  paymentOptions: IPaymentOptionsProps;
  purchaseOrder: IPurchaseOrderProps;
  purchaseOrderData: IPurchaseOrderDataProps;
  othersProducts: Array<IProductCarouselProps>;
  roletaSorte: IRouletteProps;
}

export interface ICartProps {
  id: number;
  transacao: string;
  codigoCliente: string;
  nomeCliente: string;
  emailCliente: string;
  codigoCupom: string;
  pesoTotal: number;
  totalItens: number;
  frete: number;
  descontoCupom: number;
  subtotal: number;
  subtotalComDesconto: number;
  totalSemFrete: number;
  total: number;
  possuiClube: boolean;
  utilizarSaldoClubeSidney: boolean;
  saldoClubeSidney: number;
  saldoUtilizadoClubeSidney: number;
  mensagemICMSUF: string;
  entrega: {
    codigo: string;
    nome: string;
    prazoDeEmDias: number;
    prazoAteEmDias: number;
    prazoDescricao: string;
    mensagem: string;
  };
  endereco: {
    id: number;
    padrao: boolean;
    descricao: string;
    responsavel: string;
    codigoPostal: string;
    logradouro: string;
    complemento: string;
    numero: string;
    bairro: string;
    cidade: string;
    uf: string;
    referencia: string;
    telefone: string;
    incompleto: boolean;
  };
  ultimaParcela: {
    numero: number;
    valor: number;
    desconto: number;
    taxaJuros: number;
    valorJuros: number;
    valorTexto: string;
    descricao: string;
  };
  tokenTelevendas: string;
  migrado: boolean;
  valorRestanteFreteGratis: number;
  fraseRestanteFreteGratis: string;
  valorTabelaPreco: number;
  acumularPontosLivelo: boolean;
  pontosLivelo: number;
  itens: Array<IProductCartItem>;
  brindes: Array<IGiftProductProps>;
  opcoesFrete: Array<{
    codigo: string;
    nome: string;
    valor: string;
    valorCobrado: number;
    prazoEntrega: string;
    dataAtual: Date;
    prazoEntregaDeEmDias: number;
    prazoEntregaAteEmDias: number;
    mensagem: string;
  }>;
  freteTexto: string;
  descontoCupomTexto: string;
  subtotalTexto: string;
  subtotalComDescontoTexto: string;
  totalSemFreteTexto: string;
  totalTexto: string;
  prazoTexto: string;
  saldoClubeSidneyTexto: string;
  saldoUtilizadoClubeSidneyTexto: string;
  estaVazio: boolean;
  roletaSorte?: IRouletteProps;
  ultraPremio?: IUltraPremioProps;
}

export interface IGiftProductProps {
  sku: string;
  nome: string;
  descricao: string;
  informativo: string;
  imagem: string;
  quantidade: number;
}

export interface IUpdateCartData {
  showMiniCart?: boolean;
  cart?: ICartProps;
  othersProducts?: Array<IProductCarouselProps>;
}

export interface IFreightProps {
  modalidade: string;
  prazoEntrega: string;
  totalComFrete: string;
  ultimaParcela: {
    desconto: number;
    descricao: string;
    numero: number;
    taxaJuros: number;
    valor: number;
    valorJuros: number;
    valorTexto: string;
  };
  valor: string;
}

export interface IProductCartItem {
  id: string;
  codigo: string;
  sku: string;
  permalink: string;
  nome: string;
  descricao: string;
  desconto: number;
  imagem: string;
  gtin: string;
  categoriaId: number;
  categoria: string;
  categoriaHierarquia: string;
  categoriaGoogle: string;
  marcaId: number;
  marca: string;
  precoDeOriginal: number;
  precoPorOriginal: number;
  precoTotal: number;
  precoDeTotal: number;
  quantidade: number;
  quantidadeMinimaVenda: number;
  quantidadeMaximaVenda: number;
  quantidadeMultiplaVenda: number;
  freteGratis: boolean;
  promocaoId: number;
  calcularTabelaPreco: boolean;
  valorTabelaPreco: number;
  promocao: string;
  promocaoIdGA4: string;
  pontosLivelo: number;
  itensPromocao: Array<{
    promocaoId: number;
    precoItem: number;
    desconto: number;
    quantidade: number;
    precoComDesconto: number;
    precoComDescontoTexto: string;
    precoDescontoQuantidade: number;
    precoDescontoQuantidadeTexto: string;
  }>;
  promocaoProgressivaTexto: Array<string>;
  textoPromocao: string;
  precoDeTexto: string;
  precoPorTexto: string;
  precoDeTotalTexto: string;
  precoTotalTexto: string;
  precoDe: number;
  precoPor: number;
  comissao: number;
  comissaoTexto: string;
}

export interface IPaymentOptionsProps {
  boletoBancario: IPaymentProps;
  cartaoCredito: IPaymentProps;
  cartaoDebito: IPaymentProps;
  transferenciaOnline: IPaymentProps;
  valeCredito: IPaymentProps;
  campanha: IPaymentProps;
  brinde: IPaymentProps;
  possuiOpcaoPagamento: boolean;
  pix: IPaymentProps;
}

export interface IPaymentProps {
  tipo: string;
  nome: string;
  servico: string;
  parcelas: Array<IPaymentItemProps>;
  totalParcelas: number;
  parcela: IPaymentItemProps;
  ultimaParcela: IPaymentItemProps;
  ultimaParcelaSemJuros: IPaymentItemProps;
}

interface IPaymentItemProps {
  descricao: string;
  numero: number;
  valor: number;
  taxas: number;
  juros: number;
  valorJuros: number;
  total: number;
  desconto: number;
  valorDesconto: number;
  valorTexto: string;
  valorDetalhadoTexto: string;
  valorDetalhadoCheckoutTexto: string;
}

export interface ISendPurchaseOrder {
  formaPagamento: string;
  parcela: number;
  cartao?: {
    bandeira: string;
    titular: string;
    numero: string;
    cvv: string;
    mes: number;
    ano: number;
    token: string;
  };
  cart: ICartProps;
  codigoCliente: string;
}

export interface IPurchaseOrderProps {
  pedidoId: number;
  numeroPedido: string;
  codigoTransacao: string;
  retornarCarrinho: boolean;
  redirecionarUrl: string;
}

export interface IPurchaseOrderDataProps {
  id: number;
  numero: string;
  status: string;
  transacao: string;
  cliente: {
    id: number;
    tipo: string;
    nomeCompleto: string;
    documento: string;
    dataNascimento: string;
    telefone: string;
    email: string;
  };
  entrega: {
    servico: string;
    formaEntrega: string;
    responsavel: string;
    codigoPostal: string;
    logradouro: string;
    numero: string;
    bairro: string;
    cidade: string;
    uf: string;
    complemento: string;
    telefone: string;
    dataEntregaPrevista: Date;
    agendamento_Data: Date;
    retiraEndereco: string;
    agendamento_Periodo: string;
    agendamento_Valor: number;
    codigoRastreio: string;
    linkRastreio: string;
    prazoDescricao: string;
    freteGratis: boolean;
    mensagem: string;
  };
  pagamento: {
    tipo: string;
    nome: string;
    servico: string;
    descricao: string;
    numeroParcelas: number;
    valorParcela: number;
    valorJuros: number;
    valorParcelaTexto: string;
    valorJurosTexto: string;
  };
  subtotal: number;
  desconto: number;
  credito: number;
  frete: number;
  total: number;
  criadoEm: Date;
  totalPontosLivelo: number;
  itens: Array<{
    sku: string;
    nome: string;
    descricao: string;
    quantidade: number;
    precoUnitario: number;
    desconto: number;
    precoTotal: number;
    bonificacaoClube: number;
    brinde: boolean;
    imagem: string;
    paridade: number;
    precoTotalComDesconto: number;
    precoUnitarioTexto: string;
    descontoTexto: string;
    precoTotalTexto: string;
    precoTotalComDescontoTexto: string;
    pontosLivelo: number;
  }>;
  historico: Array<{
    status: string;
    dataOcorrencia: Date;
    dataOcorrenciaFormatado: string;
  }>;
  subtotalTexto: string;
  descontoTexto: string;
  creditoTexto: string;
  freteTexto: string;
  totalTexto: string;
  criadoEmFormatado: string;
  dataEntregaPrevistaTexto: string;
  dataEntregaAgendadaTexto: string;
  totalBonificacaoClube: number;
  totalBonificacaoClubeTexto: string;
  contemImagemItens: boolean;
  totalEconomizado: number;
  totalEconomizadoTexto: string;
}

export interface IPreviewBankSlipProps {
  transacao: string;
  nossoNumero: string;
  numeroDocumento: string;
  linhaDigitavel: string;
  codigoBarras: string;
  mensagemErro: string;
  dataVencimento: Date;
  boletoUrl: string;
  qrCode: string;
  total: number;
  status: string;
  pedidoId: number;
  totalTexto: string;
  totalInteiro: number;
}

export interface IPreviewAccountDebitProps {
  banco: string;
  consultaSonda: string;
  numero: string;
  pedidoId: number;
  processado: boolean;
  processadoEm: string;
  processadoEmFormatado: string;
  situacao: string;
  status: string;
  total: number;
  totalInteiro: number;
  totalTexto: string;
  transferenciaOnlineUrl: string;
  urlSonda: string;
}

export interface IFreightCalculationResponse extends IResponse, IFreightProps { }

export interface ICartRecoveryProps {
  cartId: number;
  clientCode: string;
}

export interface IRouletteSpinProps {
  fromHome?: boolean;
}

export interface IRouletteProps {
  imagemProdutoUrl?: string;
  nomeProduto?: string;
  precoPorFormatado?: string;
  produtoId?: string;
  tipo?: string;
  valido?: boolean;
}

export interface IUltraPremioProps {
  total: number;
  valorMinimo: number;
  pedidoItensTotalUltraPremio: number;
  saldoRestante: number;
  fraseRestanteUltraPremio: string;

}
