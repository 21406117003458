import { ActionTypes, IHomeDataResponse } from './types';

export function loadHomeData(id?: string) {
  return {
    type: ActionTypes.loadHomeData,
    payload: {
      id,
    },
  };
}

export function updateHomeData({ banners, componentes }: IHomeDataResponse) {
  return {
    type: ActionTypes.updateHomeData,
    payload: {
      banners,
      componentes,
    },
  };
}
