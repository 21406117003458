import { createGlobalStyle } from 'styled-components';

import colors from './colors';

export default createGlobalStyle`

:root {
    --primary-color: #542288;
    --secondary-color: #ec135a;
    --grey-000: #fafafa;
    --grey-100: #f2f2f2;
    --grey-200: #f0f0f0;
    --grey-300: #ebebeb;
    --grey-400: #e3e3e3;
    --grey-500: #dcdcdc;
    --grey-600: #a0a0a0;
    --grey-800: #646464;
    --grey-900: #787878;
    --black-900: #0F1111;
}

.em-breve-desktop {
  display: block;
  width: 100%;
}

.em-breve-mobile {
  display: none;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .em-breve-desktop {
  display: none;
}

.em-breve-mobile {
  display: block;
}

}

   *{
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
    }

    body{
      background: ${colors.background};
      -webkit-font-smoothing: antialiased;
    }

    // Responsivo
    html {
      font-size: 62.5%;
      color: #303030;

      @media (max-width: 1080px) {
        font-size: 58%;
      }

      @media (max-width: 720px) {
        font-size: 54%;
      }

      @media (max-width: 425px) {
        font-size: 48%;
      }
    }

    body, input, button{
      font: 400 16px "Ubuntu", sans-serif;
    }

    h1, h2, h3, h4, h5, h6, strong {
      font-weight: 700;
    }

    button, a{
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

  [disabled] {
    opacity: 0.5;
  }

  .container-global {
    max-width: 1400px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }

  .loader {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: var(--primary-color);
    background: ${(props) => `linear-gradient(
    to right,
    var(--primary-color) 8%,
    #fff 25%,
    var(--primary-color) 33%
    )`} ;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 101;
  }

  .slick-track{
    margin-left: 0 !important;
  }
`;
