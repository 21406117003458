import React, { createContext, useCallback, useContext, useState } from 'react';

import NoticeModal from 'components/common/Modal/NoticeModal';

export interface INoticesProps {
  messages: Array<string>;
  type: 'warn' | 'errors' | 'allMessages';
  needConfirmation?: boolean;
  action?(): void;
}

interface INoticesContextData {
  addNotices(data: INoticesProps): void;
}

const NoticesContext = createContext<INoticesContextData>(
  {} as INoticesContextData,
);

export const NoticesProvider: React.FC = ({ children }) => {
  const [notices, setNotices] = useState<INoticesProps[]>([]);

  const addNotices = useCallback((data: INoticesProps) => {
    setNotices([data]);
  }, []);

  const removeNotices = useCallback(() => {
    setNotices([]);
  }, []);

  const onConfirm = useCallback(() => {
    if (notices[0].action) notices[0].action();
    removeNotices();
  }, [notices, removeNotices]);

  return (
    <NoticesContext.Provider value={{ addNotices }}>
      {children}
      {!!notices.length && (
        <NoticeModal
          notices={notices}
          onClose={removeNotices}
          onConfirm={onConfirm}
        />
      )}
    </NoticesContext.Provider>
  );
};

export function useNotices(): INoticesContextData {
  const context = useContext(NoticesContext);

  return context;
}
