import React, { createContext, useCallback, useContext, useState } from 'react';

import { ActivityIndicator } from 'components/common/ActivityIndicator';
import Loading from 'components/common/Loading';

interface ILoadingContextData {
  loading: boolean;
  message?: string;
  setLoadingOn(message?: string): void;
  setLoadingOff(): void;
}

const LoadingContext = createContext<ILoadingContextData>(
  {} as ILoadingContextData,
);

export const LoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const setLoadingOn = useCallback((messageLoading?: string) => {
    setMessage(messageLoading);
    setLoading(true);
  }, []);

  const setLoadingOff = useCallback(() => {
    setMessage('');
    setLoading(false);
  }, []);

  return (
    <LoadingContext.Provider
      value={{ loading, message, setLoadingOn, setLoadingOff }}
    >
      {loading && !!message && <Loading message={message} loading />}
      {loading && !message && <ActivityIndicator />}

      {children}
    </LoadingContext.Provider>
  );
};

export function useLoading(): ILoadingContextData {
  const context = useContext(LoadingContext);

  return context;
}
