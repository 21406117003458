import produce from 'immer';
import Cookies from 'js-cookie';
import { HYDRATE } from 'next-redux-wrapper';
import { Reducer } from 'redux';

import api from 'services/api';
import { objectIsEmpty } from 'utils/objectEmpty';

import {
  ActionTypes,
  IProfileState,
  IUserProps,
  IRequestItemProps,
  IUserDataProps,
} from './types';

const INITIAL_STATE: IProfileState = {
  user: {} as IUserProps,
  userData: {} as IUserDataProps,
  addresses: [],
  requests: [],
  requestViewer: {} as IRequestItemProps,
  favorities: [],
} as IProfileState;

const profile: Reducer<IProfileState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case HYDRATE: {
        const nextState = { ...draft, ...action.payload.profile };

        if (Object.keys(state.user).length) nextState.user = state.user;

        return nextState;
      }
      case ActionTypes.updateAddressList: {
        const { addresses } = action.payload;

        return { ...draft, addresses };
      }

      case ActionTypes.updateRequestList: {
        const { requests } = action.payload;
        return { ...draft, requests };
      }

      case ActionTypes.updateRequestViewer: {
        const { requestViewer } = action.payload;
        return { ...draft, requestViewer };
      }

      case ActionTypes.updateUserData: {
        const { userData } = action.payload;
        return { ...draft, userData };
      }

      case ActionTypes.updateFavoritiesList: {
        const { favorities } = action.payload;
        return { ...draft, favorities };
      }

      case ActionTypes.updateUserSessionData: {
        const { userSession } = action.payload;

        if (userSession) {
          api.defaults.headers.Authorization = `Bearer ${userSession.token}`;
          api.defaults.headers['x-uf'] = userSession.uf;

          Cookies.set('@Pet:user', JSON.stringify(userSession));

          // if (process.browser) {
          //   const authChannel = new BroadcastChannel('auth');
          //   authChannel.postMessage('signIn');
          // }
          return { ...draft, user: userSession };
        }

        return { ...draft };
      }

      case ActionTypes.signOut: {
        const { isBroadCast } = action.payload;

        Cookies.remove('@Pet:user');

        // if (process.browser) {
        //   const authChannel = new BroadcastChannel('auth');

        //   if (!isBroadCast) {
        //     authChannel.postMessage('signOut');
        //   }
        // }

        return INITIAL_STATE;
      }

      default: {
        if (process.browser) {
          const user = Cookies.get('@Pet:user');

          if (user && objectIsEmpty(draft.user)) {
            return { ...draft, user: JSON.parse(user) };
          }
        }
        return draft;
      }
    }
  });
};

export default profile;
