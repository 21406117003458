import React from 'react';

import Modal from '..';
import { INoticesProps } from 'hooks/notices';
import Button from '../../Button';
import { Container } from './styles';

interface INoticeModalProps {
  onClose(): void;
  onConfirm(): void;
  withScroll?: boolean;
  notices: INoticesProps[];
}

const NoticeModal: React.FC<INoticeModalProps> = ({
  onClose,
  onConfirm,
  withScroll,
  notices,
}) => {
  return (
    <Modal onClose={onClose} withScroll={withScroll}>
      <Container>
        {notices[0].type === 'warn' && <h2>ATENÇÃO</h2>}

        {notices.map((notice, index) => (
          <h3 key={index}>
            {notice.messages.map(message => (
              <p
                className={`${notice.type}`}
                key={message}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            ))}
          </h3>
        ))}

        {notices[0].needConfirmation ? (
          <div className="section-buttons">
            <Button onClick={onClose}>CANCELAR</Button>
            <Button onClick={onConfirm}>SIM</Button>
          </div>
        ) : (
          <Button onClick={onClose}>OK</Button>
        )}
      </Container>
    </Modal>
  );
};

export default NoticeModal;
